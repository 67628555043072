import {usePage} from '@inertiajs/vue3';
import {trans} from 'laravel-vue-i18n';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

const log = function (...data) {
    if (import.meta.env.VITE_APP_DEBUG === 'true') {
        console.log(...data);
    }
};

// @see https://stackoverflow.com/a/11381730
const isMobileOrTablet = function () {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substr(0, 4),
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

const checkAndGetLocale = (locale) => {
    locale = locale || usePage().props?.locale?.toString() || navigator.language;
    return ['it', 'en'].includes(locale) ? locale : 'en';
};

const localeDateTimeFormat = (locale, useSeconds = false) => {
    if (useSeconds) {
        return {
            it: 'DD/MM/YYYY HH:mm:ss',
            en: 'MM/DD/YYYY hh:mm:ss A',
        }[locale];
    }
    return {
        it: 'DD/MM/YYYY HH:mm',
        en: 'MM/DD/YYYY hh:mm A',
    }[locale];
};

const getUserTimeZone = () => {
    return 'Europe/Rome';
};

const localeDateFormat = (locale) => {
    return {
        it: 'DD/MM/YYYY',
        en: 'MM/DD/YYYY',
    }[locale];
};

const formatDate = (value, format, useTimezone, locale) => {
    if (value) {
        if (format) {
            return moment(String(value)).format(format);
        }

        locale = checkAndGetLocale(locale);
        format = localeDateFormat(locale);

        if (useTimezone === true) {
            const timezone = moment.tz.guess();
            return moment.utc(String(value)).tz(timezone).format(format);
        }

        return moment(String(value)).format(format);
    }
    return value;
};

const formattedDateTime = (value, locale, useSeconds = false, useTimezone = true) => {
    if (value) {
        locale = checkAndGetLocale(locale);
        const localeFormat = localeDateTimeFormat(locale, useSeconds);

        if (useTimezone === true) {
            const timezone = moment.tz.guess();
            return moment.utc(String(value)).tz(timezone).format(localeFormat);
        }

        return moment(String(value)).format(localeFormat);
    }
    return value;
};

const toNetAmount = (grossAmount, taxPercentage) => {
    if (!grossAmount || !taxPercentage) {
        return Number(grossAmount ?? 0.0);
    }
    return Number(Number(grossAmount) / ((100.0 + Number(taxPercentage)) / 100.0));
};

const toTaxAmount = (grossAmount, taxPercentage) => {
    if (!grossAmount || !taxPercentage) {
        return 0.0;
    }
    return Number((Number(grossAmount) * Number(taxPercentage)) / (100 + Number(taxPercentage)));
};

Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

const swalDialog = async function (
    title,
    text,
    icon,
    confirmButtonText,
    cancelButtonText,
    focusCancel = true,
    additionalOptions = {},
) {
    return await Swal.fire({
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: confirmButtonText || trans('backend.confirm'),
        cancelButtonText: cancelButtonText || trans('backend.cancel'),
        focusCancel: focusCancel,
        icon: icon,
        title: title,
        text: text,
        ...additionalOptions,
    });
};

const swalConfirmDialog = async function (title, text, additionalOptions = {}, focusCancel = true) {
    return await swalDialog(title, text, 'warning', null, null, focusCancel, additionalOptions);
};

const swalYesNoDialog = async function (title, text, additionalOptions = {}, focusCancel = true) {
    return await swalDialog(title, text, 'question', trans('Yes'), trans('No'), focusCancel, additionalOptions);
};

const confirmAndExecute = async (title, message, action) => {
    const result = await swalConfirmDialog(title, message);
    if (result.isConfirmed) {
        action();
    }
};

const removeEmptyObjectValues = (object) => {
    let newObject = {};
    Object.keys(object).forEach((key) => {
        if (object[key]) {
            newObject[key] = object[key];
        }
    });
    return newObject;
};

const normalizeNumber = (value) => {
    if (typeof value === 'string') {
        value = value.replace(/[^\d.,-]/g, '').trim();
        value = value.replace(',', '.');
    }
    const numericValue = parseFloat(value);
    return !isNaN(numericValue) && !isNaN(value - 0) ? numericValue : 0;
};

function flattenParams(obj, prefix = '') {
    return Object.keys(obj).reduce((acc, key) => {
        const fullKey = prefix ? `${prefix}[${key}]` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(acc, flattenParams(obj[key], fullKey));
        } else {
            acc[fullKey] = obj[key];
        }
        return acc;
    }, {});
}

export {
    log,
    isMobileOrTablet,
    getUserTimeZone,
    localeDateFormat,
    checkAndGetLocale,
    localeDateTimeFormat,
    formatDate,
    formattedDateTime,
    toNetAmount,
    toTaxAmount,
    swalConfirmDialog,
    swalYesNoDialog,
    confirmAndExecute,
    removeEmptyObjectValues,
    normalizeNumber,
    flattenParams,
};
